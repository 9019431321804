<template>
  <div
    class="container size-mobile bg-element-white p-0 min-height-second paket"
    style="font-family: Montserrat"
  >
  
    <v-dialog/>
    <!-- <alert-error ref="ae"/> -->
    <div class="container mt-4" v-if="isLoading">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
      </content-placeholders>
    </div>
    <div class="mt-4 p-4" v-else> 
      <div class="table-responsive text-left" style="font-size: 12px;font-family:roboto">
        <table class="table table-bordered table-striped mt-3"> 
          <tr>
            <th>ID</th>
            <td>{{myData.id}}</td>
          </tr>
          <tr>
            <th>Nama</th>
            <td>{{myData.name}}</td>
          </tr>
          <tr>
            <th>Lokasi</th>
            <td>{{myData.nama_tempat_kerja}}</td>
          </tr>
          <tr>
            <th>Username</th>
            <td>{{myData.username}}</td>
          </tr>
        </table>
      </div> 
      <!-- <form class="uploadFile d-flex flex-column justify-content-center"  @submit.prevent="uploadSubmit" enctype="multipart/form-data" method="post" style="position:absolute;bottom:0;width:95%;height:100%" >
          <a class="btn btn-daftar" href="" style="width: auto !important;">
              <span class="" style="float:left;margin-right:-15px;margin-top:-3px"><img src="../../assets/img/cam.png" alt="Attach" style="width:25px;"></span>
              Ambil Gambar
              <input id="file" name="file" type='file' class="attach" accept="image/*" @change="onFileChange"/>
          </a>
          <button  id="submit" class="btn btn-login mt-2" style="width: auto !important;">
            <span>Upload</span>
            
          </button>
      </form> -->
      
      <!-- <alert ref="c"/> -->
    </div>
  </div>
</template>
 
<script>   
 
import Api from "../../Api";
// import alertError from "../../components/AlertError.vue";
// import alert from "../../components/Alert.vue";

export default {
  name: "Profil",
  components: {    
    // alert,
    // alertError, 
  },
  data() {
    return {
      isLoading: true,
      datas: [],
      isLoadingUpload:false,
      total: 0,
      imgUploaded: false,
      dataModal: [],
      isModalVisible: false,
      updatedContent: "",
      token: '',
      myData: '',
      file_upload:'',
      isSubmit: false,
      dataFiltered: 0
    };
  },

  async mounted() {
    this.load();
    // console.log(JSON.parse(localStorage.getItem('cred')));
  },

  methods: {
    uploadSubmit(){
      console.log("A")
    },
    onFileChange(e) {
      const file = e.target.files[0]; 
      this.imgUploaded = URL.createObjectURL(file);
      this.file_upload = e.target.files[0] 
      // console.log(e.target.files)
      alert(e.target.files[0].lastModifiedDate)
      // alert()
    },
    async load() {
      this.myData = JSON.parse(localStorage.getItem('cred'))
          // console.log(this.datas)
      this.isLoading = false;
    },
    async update(id){
      this.isLoadingUpload = true
      const data = {
        user_id: this.myData.id,
        workplace_id: this.myData.id_tempat_kerja,
        nama_tamu: this.dataModal.nama_tamu,
        nama_driver: this.dataModal.nama_driver,
        keperluan: this.dataModal.keperluan,
        no_polisi: this.dataModal.no_polisi,
        jenis_kendaraan: this.dataModal.jenis_kendaraan,
        jam_keluar: this.dataModal.v_jam_keluar,
        jam_masuk: this.dataModal.v_jam_masuk,
        asal_instansi: this.dataModal.asal_instansi,
      }
      console.log(data)
      await Api.put("satpam/tamu/"+id,data,{
        headers: {Authorization: 'Bearer ' + this.token}
      })
      .then(() => {
        this.closeModal()
        this.load()
        this.$refs.c.showAlert('Sukses','Berhasil Update Data Tamu')
      })
      .catch((error) => {

        console.log(error);
        this.$refs.ae.showAlert()
      });
      this.isLoadingUpload = false 
    }, 
    showModal(data) {
      console.log(data)
      this.dataModal = data; 
      // this.updatedContent = data.pengirim
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    modalDelete(id){
      this.$modal.show('dialog', {
        title: 'Yakin Hapus Data kendaraan ?',
        text: 'Data yang telah dihapus tidak dapat dikembalikan',
        buttons: [
          {
            title: 'Tutup',
            handler: () => {
              this.$modal.hide('dialog')
              // this.$router.push(this.$route.query.redirect || '/')
            }
          }, 
          {
            title: 'Hapus',
            handler: () => {
              Api.delete("satpam/kendaraan/"+id,{
                headers: {Authorization: 'Bearer ' + this.token}
              })
              .then(() => {
                this.closeModal()
                this.$modal.hide('dialog')
                this.load()
              })
              .catch((error) => {

                console.log(error);
                this.$refs.ae.showAlert()
              });
              // this.$router.push(this.$route.query.redirect || '/')
            }
          }, 
        ]
      })
    }

  },
  // Untuk Search Paket
  computed: {
    filteredData() {
      var vm = this;
      var startDate = vm.startDate;
      // console.log(this.datas)
      return this.datas.filter(item => {
        var date = item.v_tgl_masuk;
        if (date == startDate) {
          // console.log(item.length)
          return date == startDate;
        }
      });
    }
  }
};
</script> 
<style scoped>
  th,td{
    padding-left:10px;
  }
</style>